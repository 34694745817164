<template>
    <div class="all">
        <Home2Header active-index="/about"></Home2Header>
        <AboutHeader2 active-index2="/about"></AboutHeader2>
        <div class="main">
            <div style="">
                <div style="width: 35%;text-align: left;float: left">
                    <h2 style="margin-top: 0">公司简介</h2>
                    <h4 style="padding-top:10px">BRIEF INTRODUCTION</h4>
                    <p style="text-indent:2em;padding-top:15px;letter-spacing:1px;color: #6c6c6c;line-height: 30px;">广州双动科技有限公司，成立于2016年，是一家专注于智能互动穿戴硬件、运动及健康内容孵化领域的移动互联网科技公司。2018年，公司技术团队利用多年体感交互技术运动算法和智能穿戴硬件的研发积累，整合并开发欧瑞斯智能互动平台。</p>
                    <p style="text-indent:2em;padding-top:10px;letter-spacing:1px;color: #6c6c6c;line-height: 30px;">双动科技一直致力于将运动和健康以科技方式更好地融入人们的生活中，以用户需求为导向，营造更加健康互动的家庭氛围。</p>
                </div>
                <img src="../../assets/About/company1.jpg" style="margin-top: 60px;height: 21vw;width: 35vw;min-width:336px;min-height:201px;float: right"/>
            </div>
            <div style="clear: both;padding-top: 100px;margin-bottom: 130px;height: 320px;">
              <el-row :gutter="100">
                <el-col :span="8">
                  <h2>专业 MAJOR</h2>
                  <h4 style="padding-top: 10px">以专业的态度打造产品</h4>
                  <p style="text-indent:2em;padding-top:20px;text-align: left;color: #6c6c6c;letter-spacing:1px;line-height: 30px;">
                    国家民族卫生协会、广州体育学院等多家专业机构与院校为品牌加持，不论是智能穿戴设备还是深度健康内容，双动旨在一丝不苟的打造高品质的专业产品。
                  </p>
                </el-col>
                <el-col :span="8">
                  <h2>轻松RELAXED</h2>
                  <h4 style="padding-top: 10px">以轻松的方式传达品牌</h4>
                  <p style="text-indent:2em;padding-top:20px;text-align: left;color: #6c6c6c;letter-spacing:1px;line-height: 30px;">
                    双动以不拘于形式、不局限于场景、不占用太多时间，以互动化、娱乐化的轻松方式快速进入丰富多样，触手可及的运动方式，达到的良好运动效果。
                  </p>
                </el-col>
                <el-col :span="8">
                  <h2>健康HEALTHY</h2>
                  <h4 style="padding-top: 10px">以健康为愿景的最终使命</h4>
                  <p style="text-indent:2em;padding-top:20px;text-align: left;color: #6c6c6c;letter-spacing:1px;line-height: 30px;">
                    身心健康，延年益寿是人生的第一财富，也是生活之中永恒的主题。运动、睡眠、康复、饮食等综合解决方案都是为更好地健康而来，并以此作为最终使命。
                  </p>
                </el-col>
              </el-row>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "../../components/Footer";
    import Home2Header from "../../components/Home2Header";
    import AboutHeader2 from "../../components/AboutHeader2";

    export default {
        name: "About",
        components: {
          Home2Header, AboutHeader2, Footer
        },
        props: {
            activeIndex: {
                type: String
            },
            activeIndex2: {
                type: String
            }
        },
        data() {
            return {

            }
        },
        methods: {},
    }
</script>

<style scoped>
    .all{
        min-width:960px;
    }
    .main {
        margin: 50px 10% 50px 10%;
    }
</style>